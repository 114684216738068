import React from 'react'
import PropTypes from 'prop-types'
import { Box, Image, Link } from 'rebass/styled-components'

import IconFooterIphone from '../images/icon-footer-iphone.svg'
import IconFooterEmail from '../images/icon-footer-email.svg'
import IconFooterFacebook from '../images/icon-footer-facebook-filled.svg'

const FooterContactLink = ({ children, href, ...rest }) => (
  <Link href={href} variant="footerContactLink" {...rest}>
    {children}
  </Link>
)

const FooterContactLinks = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: ['row', 'row', 'column'],
        gap: 3,
        justifyItems: 'center',
      }}
    >
      <FooterContactLink href="tel:+420777800483" title="Telefon">
        <Image src={IconFooterIphone} alt="Telefon" /> +420 777 800 483
      </FooterContactLink>

      <FooterContactLink href="mailto:info@autovrakyhrubes.cz" title="E-mail">
        <Image src={IconFooterEmail} alt="E-mail" /> info@autovrakyhrubes.cz
      </FooterContactLink>

      <FooterContactLink
        href="https://www.facebook.com/autovrakyhrubes"
        target="_blank"
        title="Facebook"
      >
        <Image src={IconFooterFacebook} alt="Facebook" /> Facebook
      </FooterContactLink>
    </Box>
  )
}

FooterContactLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

export default FooterContactLinks
