const colors = {
  lightGray: '#E8E5E5',
  accent: '#EE2F46',
  primary: '#231F20',
  bodyBg: '#F4EFEF',
  footerBg: '#231F20',
  dangerRed: '#EE2F46',
}

const light = {
  breakpoints: ['40em', '62em', '72em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  colors,
  fonts: {
    body: 'Termina, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  lineHeights: {
    heading: 1.25,
  },
  fontSizes: [0, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 52, 56, 64],
  fontWeights: {
    heading: 700,
  },
  shadows: {
    bigShadow: '0 16px 48px rgba(178, 121, 121, 0.25)',
    bigShadowHover: '0 20px 56px rgba(178, 121, 121, 0.35)',
  },
  // Text
  text: {
    heading: {
      color: colors.accent,
    },
    sectionTitle: {
      variant: 'text.heading',
      fontSize: 10,
    },
    hero: {
      title: {
        fontSize: ['1.5rem', '2.5rem'],
        color: colors.primary,
        '& small': {
          fontSize: '1.5rem',
        },
      },
    },
    footer: {
      title: {
        fontSize: 8,
      },
      copyright: {
        color: '#d0c0c0',
        fontSize: 5,
        '& a': {
          color: 'inherit',
        },
      },
    },
    // Contact form
    errorMessage: {
      m: 0,
      p: 2,
      bg: '#FCE8E8',
      borderRadius: '4px',
      color: 'dangerRed',
      textAlign: 'center',
    },
  },
  // Buttons
  buttons: {
    primary: {
      py: 3,
      display: 'grid',
      justifyContent: 'center',
      bg: 'primary',
      borderRadius: '4px',
      cursor: 'pointer',
      fontFamily: 'body',
      fontWeight: 700,
      transition: 'all 200ms ease-out',
      '&:hover, &:focus': {
        bg: 'accent',
      },
      '&:disabled': {
        bg: 'primary',
        cursor: 'wait',
      },
    },
  },
  // Variants
  variants: {
    // PhoneLink
    phoneLink: {
      display: 'grid',
      gridAutoFlow: 'column',
      gap: 2,
      alignContent: 'center',
      justifyContent: 'center',
      mb: 4,
      py: 2,
      px: 3,
      bg: 'white',
      borderRadius: '4px',
      boxShadow: 'bigShadow',
      fontSize: 6,
      fontWeight: 700,
      textAlign: 'center',
      textDecoration: 'none',
      transition: 'all 200ms ease-out',
      '&:hover, &:focus': {
        bg: 'lightGray',
      },
    },
    // Card
    card: {
      p: 4,
      bg: 'white',
      borderRadius: '4px',
    },
    // Header
    header: {
      py: [3, 3, 4],
      logo: {
        width: 'auto',
        height: '4rem',
      },
      'logo-link': {
        display: 'grid',
        gridAutoFlow: 'column',
        maxWidth: ['50%', 'max-content'],
        alignItems: 'center',
        gap: '0.75rem',
        fontSize: '2rem',
        fontWeight: 700,
        textDecoration: 'none',
      },
    },
    headerContent: {
      display: 'grid',
      gridTemplateColumns: ['auto', 'auto auto'],
      alignItems: 'center',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    // Footer
    footer: {
      py: 5,
      bg: 'footerBg',
      color: '#fff',
      body: {
        display: 'grid',
        gap: '24px',
        justifyContent: 'center',
        textAlign: 'center',
      },
    },
    footerContactLink: {
      width: 'max-content',
      height: 'max-content',
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      gap: 3,
      py: 2,
      px: 4,
      bg: 'white',
      borderRadius: '4px',
      color: colors.primary,
      fontSize: [3, 4, 6],
      fontWeight: 700,
      lineHeight: 1.5,
      transition: 'all 200ms ease-out',
      textDecoration: 'none',
      whiteSpace: 'word-break',
      '&:hover': {
        transform: 'translateY(-0.25rem)',
        zIndex: 1,
      },
      '&:active': {
        transform: 'translateY(0)',
        zIndex: 1,
      },
      '& img': {
        lineHeight: 0,
      },
    },
    // Hero
    hero: {
      pb: 5,
      body: {
        display: 'grid',
        gap: '40px',
        gridTemplateColumns: ['1fr', '1fr', '1fr 1.5fr'],
        alignItems: 'center',
        py: 5,
      },
      illustration: {
        width: '100%',
        maxWidth: '40rem', // 480px
        height: '16rem',
      },
      content: {
        display: 'grid',
        gap: 4,
      },
    },
    benefits: {
      iconImage: {
        width: '2rem',
        height: '2rem',
        lineHeight: 0,
      },
      icon: {
        p: 3,
        borderRadius: '4px',
        lineHeight: 0,
        width: 'max-content',
      },
      iconDefault: {
        variant: 'variants.benefits.icon',
        bg: '#f8f0f0',
      },
      iconSell: {
        variant: 'variants.benefits.icon',
        bg: '#FDFAD7',
      },
      iconOffer: {
        variant: 'variants.benefits.icon',
        bg: '#C8FBBB',
      },
      iconLaw: {
        variant: 'variants.benefits.icon',
        bg: '#E2E2FA',
      },
    },
    // Input group
    inputGroupWrapper: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: [
        'minmax(auto, 204px) 36px',
        'minmax(auto, 240px) 36px',
      ],
      alignItems: 'center',
      p: 2,
      bg: '#F9FAFB',
      borderRadius: '4px',
    },
    inputGroupWrapperBare: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: ['24px 12rem auto'],
      alignItems: 'center',
      gap: 2,
      p: 2,
      pl: 3,
      bg: '#F9FAFB',
      borderRadius: '4px',
    },
  },
  // Forms
  forms: {
    transparentInput: {
      appearance: 'none',
      border: 'none',
      color: 'primary',
      fontFamily: 'body',
      outline: 'none',
    },
  },
  // Root styles
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
  },
}

export default light
