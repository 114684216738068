import React from 'react'
import PropTypes from 'prop-types'
import { Reset } from 'styled-reset'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from '../styles/globalStyle'
import light from '../themes/default'
import Header from './header'
import Footer from './footer'

const Layout = ({
  children,
  headerTitle,
  footerTitle,
}) => {
  return (
    <ThemeProvider theme={light}>
      <Reset />
      <GlobalStyle />

      <Header headerTitle={headerTitle} />

      <main>{children}</main>

      <Footer footerTitle={footerTitle} />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerTitle: PropTypes.string,
  footerTitle: PropTypes.string,
}

export default Layout
