import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Box, Link as RebassLink, Image } from 'rebass/styled-components'

import Container from './container'
import Logo from '../images/logo.svg'

const Header = ({ headerTitle }) => {
  return (
    <Box as="header" variant="header">
      <Container>
        <Box variant="headerContent">
          <RebassLink as={Link} to="/" variant="header.logo-link">
            <Image src={Logo} alt={headerTitle} variant="header.logo" />
          </RebassLink>
        </Box>
      </Container>
    </Box>
  )
}

Header.propTypes = {
  headerTitle: PropTypes.string,
}

Header.defaultProps = {
  headerTitle: '',
}

export default Header
