import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Box, Heading, Text } from 'rebass/styled-components'

import Container from './container'
import FooterContactLinks from './footer-contact-links'

const Footer = ({ footerTitle }) => (
  <Box as="footer" variant="footer">
    <Container>
      <Box variant="footer.body">
        <Heading as="h2" variant="footer.title">
          {footerTitle}
        </Heading>

        <FooterContactLinks />

        <Text as="p" variant="footer.copyright">
          {`Copyright © 2017-${new Date().getFullYear()} ${footerTitle}.`}{' '}
          <Link to="/zpracovani-osobnich-udaju">Zpracování osobních údajů</Link>
        </Text>
      </Box>
    </Container>
  </Box>
)

Footer.propTypes = {
  footerTitle: PropTypes.string,
}

export default Footer
