import { css } from 'styled-components'

export default ({ primary, secondary }) => css`
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    border-radius: 12px;
  }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
      content: '';
      display: table;
    }

    &:after {
      clear: both;
    }

    .slick-loading & {
      visibility: hidden;
    }
  }
  .slick-slide {
    float: left;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    min-height: 1px;
    line-height: 0;
    [dir='rtl'] & {
      float: right;
    }
    img {
      display: block;
    }
    &.slick-loading img {
      display: none;
    }

    display: none;

    &.dragging img {
      pointer-events: none;
    }

    .slick-initialized & {
      display: block;
    }

    .slick-loading & {
      visibility: hidden;
    }

    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }

  // Arrows
  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    appearance: none;
    cursor: pointer;
    z-index: 2;
    background: transparent;
    border: none;
    outline: none;
  }

  .slick-arrow.slick-prev {
    left: 0.5rem;
  }

  .slick-arrow.slick-next {
    right: 0.5rem;
  }

  // Dots
  .slick-dotted.slick-slider {
    /* margin-bottom: 4rem; */
  }

  .slick-dots {
    position: absolute;
    bottom: 1rem;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      position: relative;
      display: inline-block;
      height: 1rem;
      width: 1rem;
      margin: 0 0.25rem;
      padding: 0;
      cursor: pointer;

      button {
        border: 0;
        background: transparent;
        display: block;
        height: 2rem;
        width: 2rem;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 0;
        cursor: pointer;

        &:hover,
        &:focus {
          outline: none;
        }
        &:before {
          height: 0.5rem;
          width: 0.5rem;
          border-radius: 0.5rem;
          background-color: white;
          position: absolute;
          top: 50%;
          left: 0;
          content: '';
        }
      }
      &.slick-active button:before {
        background-color: ${primary};
      }
    }
  }
`
