import { createGlobalStyle } from 'styled-components'

import slickCarouselStyles from './slickCarouselStyles'

const GlobalStyle = createGlobalStyle`
  html {
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
  }

  body {
    background-color: ${(props) => props.theme.colors.bodyBg};
    color: ${(props) => props.theme.colors.primary};
    font-family: ${(props) => props.theme.fonts.body};
  }

  a {
    color: ${(props) => props.theme.colors.primary};
  }

  p, ul, ol {
    line-height: 1.75;
  }

  strong {
    font-weight: 700;
  }

  ${slickCarouselStyles({
    primary: (props) => props.theme.colors.primary,
    secondary: (props) => props.theme.colors.primary,
  })}
`

export default GlobalStyle
