import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

const Container = ({ children, width = 1040, px = [3, 3, 0] }) => (
  <Box
    sx={{
      maxWidth: width,
      mx: 'auto',
      px,
    }}
  >
    {children}
  </Box>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  px: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
}

Container.defaultProps = {
  width: 1040,
  px: [3, 3, 0],
}

export default Container
